/* You can add global styles to this file, and also import other style files */
@import 'maplibre-gl/dist/maplibre-gl.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter.ttf');
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Regular.ttf');
}

body {
    background-image: url('assets/img/fondo.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

@media (max-width: 1500px) {
    body {
        width: 1500px;
      }    
  }
  